<template>
  <div class="bookmarks-tabs">
    <v-item-group v-model="active" class="d-flex" mandatory>
      <v-item v-for="(v, i) in list" :key="i" v-slot="{ active, toggle }">
        <v-btn class="tab-action mr-4 mr-lg-6 flex-grow-lg-1" :class="{'item-active':tabIdx==i}" :input-value="active" @click="toggle();btnclick(i);">
			<!-- <img class="img" src="../assets/icons/icon_creation.png" /> -->
		  <!-- <span class="icon" :class="'icon-'+i"></span> -->
          {{ v }}
        </v-btn>
      </v-item>
    </v-item-group>
    <v-window v-model="active">
      <v-window-item v-for="(v, i) in list" :key="i">
        <slot :index="i" />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang='js'>

export default {
  name: 'BookmarksTabs',
  props: {},
  data: function() {
    return {
        active: "",
        list: [this.$t("Collectibles"), this.$t("MyCreations")],
        tabIdx: 0,
	
    }
  },
  mounted() {
      window.eventBus.$on('LangChange', this.onLangChange);

      let i = sessionStorage.getItem("PersonalPage_Tabs");
      if(i == 1) {
          this.active = parseInt(i);
          this.btnclick(this.active);
          sessionStorage.removeItem("PersonalPage_Tabs");
      }
	 
  },
  methods: {
      btnclick(i) {
          this.$emit("onTabsClick",i);
          this.tabIdx = i;
      },
      onLangChange() {
        this.list = [this.$t("Collectibles"), this.$t("MyCreations")];
      }
  },
};

</script>

<style lang='scss' scoped>
.bookmarks-tabs {
  .tab-action {
    height: unset;
    font-size: 23px;
    letter-spacing: 0px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#270645, #270645);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.17);
    border-bottom: none;
    border-radius: 20px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
	color: #766983;
    padding: 10px 18px;
     font-weight: bold;
      font-family: Helvetica;font-weight: bold;
  }
  
  .icon {
	  width: 30px;
	  height: 30px;
	  /* background-image: url(../assets/icons/icon_creation.png); */
	  background-size: 100%;
  }
  
  // .icon-0 {
	//   background-image: url(../assets/icons/icon_collections1.png);
  //     margin-right: 10px;
  // }
  // .icon-1 {
	//   background-image: url(../assets/icons/icon_creation1.png);
  //     margin-right: 10px;
  // }
  
  .item-active {
      color: #1d42ff !important;
      font-weight: bold;
      font-family: Helvetica;font-weight: bold;
      
      // .icon-0 {
      // 	  background-image: url(../assets/icons/icon_collections.png) !important;
      // }
      // .icon-1 {
      // 	  background-image: url(../assets/icons/icon_creation.png) !important;;
      // }
  }
  .v-btn:before {
      background-color: #fff;
  }
  
}
</style>

<template>
	<div id="container" :class="{ contain: true, mobile: isMobile }">
		<!-- <v-app>
      <v-dialog v-model="newOpen" persistent width="80%"> -->
		<v-card class="card d-flex flex-column py-10 px-8">
			<img class="clo" @click="closeDialog" src="@/assets/icons/btn_close.png" alt="" />
			<div class="box">
				<img class="large-img"  :src="productPic" alt="" v-if="!videoPic || videoPic=='null'" />
				<video autoplay loop 
                controls
                controlslist="nodownload" :src="videoPic" v-if="videoPic"></video>
			</div> 
		</v-card>
		<!-- </v-dialog>
    </v-app> -->
	</div>
</template>
<script>
	import largeImg from "@/assets/test.png";
	export default {
		data: () => ({
			largeImg,
			newOpen: true,
		}),
		props: {
			productPic: {
				type: String
			},
			videoPic: {
				type: String
			}
		},
		// props: {
		//   imgStr: String,
		//   default: "@/assets/test.png",
		// },
			computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
      },
		methods: {
		
			//提交转送数据
			subCommit() {},
			//关闭弹框，发送open值
			closeDialog() {
				this.open = false;
				this.$emit("getOpen", this.open);
			},
		},
	};
</script>
<style lang="scss" scoped>
	#container{
		height: 100%;
	}
	.contain {
		height: 100%;
		.card {
		//   min-height: 748px;
		height: 100%;
		width: 100%;
		background-color: #fff;

		.clo {
			position: absolute;
			right: 40px;
			top: 40px;
			width: 30px;
			height: 31px;
			background-color: #fff;
			border-radius: 50%;
			margin-right: 0;
			cursor: pointer;
			border-radius: 50% !important;
			z-index: 99;
		}

		.box {
			// margin: 20px auto;
			width: 100%;
			height: 100%;
			text-align: center;

			img {
				width: auto;
				height: 100%;
				width: 100%;
				object-fit: contain;
				// border-radius: 40px;
			}

			video{
				width: auto;
				height: 100%;
			}
		}
	}
	&.mobile {
		.card {
			width: 100%;
			height: 100%;
		}
		.clo {
			position: absolute;
			right: 30px;
			top: 30px;
			width: 30px;
			height: 30px;
		}
		.box{
			width: 100%;
			display:flex;
			align-items: center;
			img{
				width: 100%;
				height:auto;
			}
			video{
				width: 100%;
				height:auto;
			}
		}



	}
}

	
</style>


<style>
	.v-dialog--fullscreen {
		width: 100% !important;
		height: 100% !important;
	}
</style>

<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div
      class="user-card d-flex flex-column align-center justify-center"
      style="cursor: pointer"
      @click="click"
    >
      <!-- <v-avatar :size="70">
        <v-img :src="value.issuerImg" alt="Midoriya" />
      </v-avatar> -->
      <Avatar size="70" :hash="value.creatorPhoto" @click="click" />
      <div class="d-flex flex-column align-center">
        <div class="font-weight-medium creatorName">{{ value.creatorName }}</div>
        <!-- <div v-if="!isMobile" class="text-caption">{{ value.creator }}</div>
        <div v-if="isMobile" class="text-caption">
          {{ value.creator | didfilter }}
        </div> -->
      </div>
      <div class="font-weight-medium">Listed {{ value.amount }}</div>
    </div>

    <v-dialog class="px-50" v-model="dialog" width="1040">
      <v-card
       id="box"
        class="px-10 overflow-auto"
        height="770"
        style="padding-top: 40px; position: relative"
      >
        <v-icon
          color="primary"
          style="top: 16px; right: 19px; position: absolute"
          size="30"
          @click="dialog = false"
        >
          mdi-close-circle</v-icon
        >
        <!-- <Cards :cards="cards" /> -->
        <CardList
          :list="list"
           v-scroll:#box="onScroll"
          type="page"
          :owerfrom="owerfrom"
          :isMinPrice="isMinPrice"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang='js'>
// import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/UserCard.vue";
import Avatar from '@/components/avatar/index.vue';
import api from "@/api"


export default {
  name: 'UserCard',
  components: { CardList, Avatar },
  props: {
    value: Object,
    owerfrom: String,
    isMinPrice: String
  },
  data: function () {
    return {
      dialog: false,
      list: [],
      pageNumber: 0,
      pageSize: 20,
      totalPage: false,
	    sort:1
    };
  },
  filters: {
    didfilter: function (value) {
      if (value && value.length > 12) {
        return value.substr(0, 6) + "..." + value.substr(-6);
      } else {
        return "";
      }
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    click: function () {
      this.dialog = true;
      this.getList()
    },
    async getList() {
      this.pageNumber++;
      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner: this.$store.state.did,
        creator: this.value.creator,
		    sort:1
      };
      let res = await api.home.getNftOwn(params);
      let { list, totalPage } = res.data;
      this.totalPage = totalPage;
      this.list = this.list.concat(list);
    },
    onScroll(e) {
         const {scrollTop, clientHeight, scrollHeight} =
           e.target;
         if (scrollTop + clientHeight + 10 >= scrollHeight && (this.totalPage > this.pageNumber)) {
            this.getList();
         }
      },
  }
};

</script>

<style lang='scss' scoped>
.contain {
  height: auto;

  .user-card {
    width: 180px;
    height: 206px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #cccccc;

    &:hover {
      border: solid 2px #1d42ff;
    }

    .font-weight-medium {
      margin: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .creatorName{
      width: 120px;
      text-align: center;
       overflow: hidden;
              white-space: nowrap;  
              text-overflow: ellipsis;
    }
  }

  &.mobile {
      .user-card {
          width: 100%;
          .creatorName {
              width: 100px;
              text-align: center;
          }
      }
  }
}
</style>

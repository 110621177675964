<template>
  <div :class="{ mobile: isMobile }">
    <!-- <v-tabs background-color="#fff" color="#270645" height="40px" v-model="activeTab">
        <v-tab append v-for="item in tabList" :key="item.value">{{
          item.label
        }}</v-tab>
      </v-tabs>  -->
    <div class="switch d-flex justify-space-between">
      <div class="image" v-if="activeSort != 4">
        <img class="nft" :src="nftImage" @click="mode_click('nft')"  @mouseenter="mousednft()"
                @mouseleave="leavenft()"/>
        <img
          class="collection"
          :src="collectionImage"
          @click="mode_click('collection')"
           @mouseenter="mousedcollect()"
            @mouseleave="leavecollect()"
        />
       
      </div>
      <div class="image" v-else></div>
      <div class="select d-flex flex-row" >
        <div class="clearmode d-flex flex-row" v-if="showModel == 'nft' && activeSort != 4 && !isMobile">
          <div class="checkbox">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content">
            {{ $t("Clean Mode") }}
          </div>
        </div>
        <SortSelect
          class="mr-1 dot mt-lg-1 ml-15"
          :list="selectList"
          v-model="activeSort"
        />
        <img
          :class="[rotate ? 'go' : '']"
          class="refresh"
          src="@/assets/icons/icon_refresh.png"
          @click="refresh"
        />
      </div>
    </div>
    <div class="explain">
         <div class="nftexplain d-flex flex-row align-center" v-if="isshownft" >
                <span>On-chain NFTs</span>
          </div>
          <div class="collectexplain d-flex flex-row align-center"  v-if="isshowcollect">
                <span>On-chain Collections</span>
              </div>
    </div>
   

    <v-window v-model="activeSort" v-if="showModel == 'nft'">
       <v-window-item value="1">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container mt-4">
        
             <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="cardType"
          />
          
         
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="0">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="cardType"
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="2">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            :type="cardType"
            owerfrom="personalPage"
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
       <v-window-item value="3">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="cards-container">
          <CardList
            :list="list"
            v-scroll="onScroll"
            owerfrom="personalPage"
            :type="cardType"
          />
          <loading :isShowLoading="isShowLoading"></loading>
        </div>
      </v-window-item>
      <v-window-item value="4">
        <div v-if="empty" class="empty">{{ $t("EmptyHere") }}</div>
        <div class="d-flex flex-wrap user-list">
          <UserCard
            class="mb-3 user-card"
            v-for="(item, i) in users"
            :key="i"
            :value="item"
            owerfrom="personalPage"
            isMinPrice="lastprice"
          />
        </div>
        <loading :isShowLoading="isShowLoading"></loading>
      </v-window-item>
    </v-window>

    <div class="collect" v-if="showModel == 'collection'">
      <div class="card" v-for="(item, index) in list" :key="index">
        <collect-card
         v-scroll="onScroll"
          :pageValue="item"
          :hash="item.imgUrl"
          :did="did"
          type="createorPage"
          collectsource="createCollect"
          showAvatar
        >
        <v-btn class="uc createnft" v-if="item.contractStatus!=2&&item.owner == did" width="150px" height="41px" rounded @click="createNft(item)">+ Create NFT</v-btn>
        <img
                v-if="item.owner == did"
                class="ml-5 edit"
                src="@/assets/icons/icon_edit.png"
                width="25px"
                height="25px"  
                @click="editCollection(item)"
                alt=""
                @mouseenter="moused(item.id)"
                 @mouseleave="leave()"
                
            />
           <div class="editExplain d-flex flex-row align-center"  v-if="isshow &&  share_id == item.id ">
                <span>Edit Collection</span>
          	</div>
        </collect-card>
      </div>
    </div>
           <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
  </div>
</template>

<script lang='js'>
import SortSelect from '@/components/SortSelect.vue';
import UserCard from "./UserCard.vue";
//   import Cards from "@/views/home/components/Cards.vue";
import CardList from "@/components/workCard/CardList.vue";
import api from "@/api"
import Loading from "@/components/loading.vue";
import CollectCard from '@/components/workCard/CollectCard.vue';
import {share} from "@/utils";
 import Banprompt from "@/components/popup/banprompt.vue";
export default {
  name: 'Collections',
  components: { SortSelect, UserCard, CardList, Loading, CollectCard,share,Banprompt},
  props: {},
  data: function () {
    return {
      Banprompt:false,
      activeTab: undefined,
      tabList: [
        { value: "undefined", label: this.$t('otherpageTabsPageAll') },
        { value: "3", label: this.$t('otherpageTabsPageArtwork') },
        { value: "4", label: this.$t('amCreateCollectibles') },
        { value: "5", label: this.$t('createCategoriesPhotography') },
        { value: "7", label: this.$t('amCreateVideo') }
      ],

      activeSort: '0',
      selectList: [
        { value: '0', label: "All"},
        { value: '1', label: "My Creations" },
        { value: '2', label: "On Uptick" },
        { value: '3', label: "On Loopring" },
        { value: '4', label: this.$t('ByCreator') },

      ],
      list: [],
      pageNumber: 1,
      pageSize: 20,
      totalPage: false,
      users: [],
      loading: false,
      isShowLoading: false,
      empty: false,
      rotate:false,
      checkbox:false,
      isshownft:false,
      isshowcollect:false,

      showModel: "nft", // collection
      nftImage: require("@/assets/icons/icon_cj_nft.png"),
      collectionImage: require("@/assets/icons/icon_cj_collection_g.png"),
      cardType:'my',
       NFTInfo:{
        collectionImgUrl:'',
        name:'',
        linkUrl:'',
        description:'',
        collectionMetadata:'',
        id:''
      },
      isshow:false,
         share_id: 0,
    };
  },
  computed: {
    isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		},
    handler: function () {
      return {
        activeTab: this.activeTab,
        activeSort: this.activeSort,
      }
    }
  },
  watch: {
    handler: {
      handler: function () {
        this.pageNumber = 1;
        this.list = [];
        this.users = [];

      
        if (this.showModel == "nft") {
        this.getNftList();
      } else if (this.showModel == "collection") {
        this.getCollectionList();
      }

      },
      deep: true
    },
    checkbox(ischeck){
      
      if(ischeck){
        console.log("wxl --- ",ischeck)
        this.cardType = "clearmode"
      }else{
        this.cardType = "my"
      }
    },
    isMobile() {
      if(this.$vuetify.breakpoint.mobile) {
        this.checkbox = true;
      }
    }
  },
  mounted() {
    window.eventBus.$on('nftAppealed', this.nftAppealed);
    this.init();
  },
  methods: {
    init(){
        this.did = this.$store.state.did
    if(this.$vuetify.breakpoint.mobile) {
        this.checkbox = true;
      }
      
    window.eventBus.$on('LangChange', this.onLangChange);
       window.eventBus.$on('collectionPay', this.collection);
       let i=localStorage.getItem("collection_card");
       if(i==1){
         this.mode_click("collection");
        
       }else{
         this.mode_click("nft");
       }
	   
   if (this.showModel == "nft") {
     this.getNftList();
   } else if (this.showModel == "collection") {
     this.getCollectionList();
   }
 },
    nftAppealed(){
      this.init();
    },
    collection(){
      this.list = []
				this.pageNumber = 1
      if (this.showModel == "nft") {
        this.getNftList();
      } else if (this.showModel == "collection") {
        this.getCollectionList();
      }
      
    },
    moused(id) {
       this.share_id != 0 ? (this.share_id!=id?this.share_id=id:this.share_id=0) : this.share_id = id;
				console.log("wxl --- 3333",id)
     			 this.isshow = true;
   			 },
   			 leave() {
            this.share_id = 0
     			 this.isshow = false;
   			 },
    async getNftList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let index = 0;
      if (this.activeTab == 0) {
        index = ''
      } else if (this.activeTab == 1) {
        index = 3
      } else if (this.activeTab == 2) {
        index = 4
      } else if (this.activeTab == 3) {
        index = 5
      } else if (this.activeTab == 4) {
        index = 7
      }
      
      this.isShowLoading = true;
      //创建者筛选

      if (this.activeSort == 4) {
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          owner: this.$store.state.did,
      
        };
        let response = await api.home.getOwnByCreator(params);
        // let {users, totalPage} = response.data;
        // this.totalPage = totalPage;
        let user = response.data.list;
			  user.forEach((v) => {
			  	if (!v.creatorName) {
			  	  v.creatorName = v.creator.substr(0, 6)+"..."+v.creator.substr(-6);;
			  	}
			  	
			  });
        this.users = this.users.concat(user);
        if (this.pageNumber == 1 && user.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

      } else {
        let params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          owner: this.$store.state.did,
          filter: this.activeSort,
        };
        let res = await api.home.getNftOwn(params);
        let { list, totalPage } = res.data;
        this.totalPage = totalPage;
		if(this.pageNumber == 1 || this.pageNumber == 0){
			this.list = list;
			
		}else{
			 this.list = this.list.concat(list);

		}
       
        if ((this.pageNumber == 1 || this.pageNumber == 0) && list.length == 0) {
          this.empty = true;
        } else {
          this.empty = false;
        }

      }
      this.loading = false;

      this.isShowLoading = false;

      this.$emit("list", this.list);

    },
    async getCollectionList() {
      this.loading = true;
      this.isShowLoading = true;

      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner: this.$store.state.did,
         filter: this.activeSort,
      };
      let res = await api.home.getOwnByCollection(params);
      let { list, totalPage } = res.data;
      this.totalPage = totalPage;
	  if(this.pageNumber == 1 || this.pageNumber == 0) {
		  this.list = list;
	  }else{
		   this.list = this.list.concat(list);
	  }
     

      if ((this.pageNumber == 1 || this.pageNumber == 0) && list.length == 0) {
        this.empty = true;
      } else {
        this.empty = false;
      }
      this.loading = false;
      this.isShowLoading = false;
    },
    mode_click(mode) {
      this.showModel = mode;
      mode == "nft" ? this.nftImage = require("@/assets/icons/icon_cj_nft.png") : this.nftImage = require("@/assets/icons/icon_cj_nft_g.png");
      mode == "collection" ? this.collectionImage = require("@/assets/icons/icon_cj_collection.png") : this.collectionImage = require("@/assets/icons/icon_cj_collection_g.png");
      this.pageNumber = 1;
      this.list = [];
      let isByCreate 
      if (mode == "nft") {
        this.getNftList();
        if(this.selectList.length == 2)
        this.selectList.push(
            { value: '2', label: "On Uptick" },
        { value: '3', label: "On Loopring" },
          {  value: '4', label: this.$t('ByCreator') }
          )
          localStorage.setItem("collection_card", 0);
      } else if (mode == "collection") {
        this.getCollectionList();
        if(this.selectList.length == 5){
          this.selectList.pop(this.selectList.length -1);
          this.selectList.pop(this.selectList.length -2);
          this.selectList.pop(this.selectList.length -3);
          localStorage.setItem("collection_card", 1);
        }
       
      }
    },
     async createNft(item) {
      let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.$router.push({ name: "CreateNft", query: { tokenAddress: item.address } });
            }
      // this.$router.push({ name: "CreateNft", query: { tokenAddress: item.address } });
    },
    editCollection(item){

      console.log("wxl --  item ",item)
       this.NFTInfo.collectionImgUrl = item.imgUrl
        this.NFTInfo.name = item.name
        this.NFTInfo.linkUrl = item.linkUrl?item.linkUrl:item.address
        this.NFTInfo.description = item.description
        this.NFTInfo.collectionMetadata = item.collectionMetadata
        this.NFTInfo.id = item.id
        this.$router.push({ name: "Create",params:{
    	  NFTInfo:this.NFTInfo
    } });


      //  this.$router.push({name:'Create'})
    },
     openBanprompt(e){
         this.Banprompt = e
      },
    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;
      if (Math.floor(scrollTop) + clientHeight >= scrollHeight && (this.totalPage > this.pageNumber)) {
         if (!this.loading) {
        this.pageNumber++;
      }
       
       if (this.showModel == "nft") {
         this.getNftList();
       } else if (this.showModel == "collection") {
         this.getCollectionList();
       }
      }
    },
    	    refresh(){
     this.rotate=true;
 
     setTimeout(() => { this.rotate=false }, 2000);
     this.list = []
     this.users = []
        this.pageNumber = 1
		 api.home.loopringUpdateDate({ address:this.$store.state.did})
		
        if (this.showModel == "nft") {
        this.getNftList();
      } else if (this.showModel == "collection") {
        this.getCollectionList();
      }
    
     
    },
     mousednft() {
      console.log("33333333");
      this.isshownft = true;
    },
    leavenft() {
       this.isshownft = false;
    },
    mousedcollect(){
    this.isshowcollect = true
    },
     leavecollect() {
     this.isshowcollect = false
    },
    onLangChange() {
      this.tabList = [
        { value: "undefined", label: this.$t('otherpageTabsPageAll') },
        { value: "3", label: this.$t('otherpageTabsPageArtwork') },
        { value: "4", label: this.$t('amCreateCollectibles') },
        { value: "5", label: this.$t('createCategoriesPhotography') },
        { value: "7", label: this.$t('amCreateVideo') }
      ];
      this.selectList = [
        { value: '1', label: this.$t('ByReceivingTime') },
        { value: '2', label: this.$t('ByCreationTime') },
        { value: '3', label: this.$t('ByCreator') },

      ];
    }
  },

};

</script>

<style lang='scss' scoped>
.t {
  position: relative;

}


.switch {
  margin-top: 10px;
  height: 65px;
  position: relative;
  .image {
    display: flex;
    align-items: center;
    cursor: pointer;
    .nft {
      width: 22px;
      height: 28px;
    }
  
    .collection {
      width: 25px;
      height: 25px;
      margin-left: 30px;
    }
 
  }
  .select {
      ::v-deep .sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
    display: flex;
    align-items: center;
    .clearmode {
      height: 65px;
      .content {
        display: flex;
        align-items: center;
        	font-size: 14px;
          font-weight: bold;
      }
    }
    .refresh {
      height: 18px;
      width: 18px;
      margin-top: 5px;
      margin-left: 15px;
    }

    .go {
      transform: rotate(360deg);
      transition: all 2s;
      pointer-events: none;
      user-select: none;
    }
  }
}
.explain{
  position: relative;
 
  .nftexplain{
       
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: -10px;
      left: 0;
      z-index:2;
    
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
         width: 115px;
      }
 
    }
       .collectexplain{
    
      height: 31px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: -10px;
      left: 70px;
      z-index:1;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
        width: 125px;
      }

    }

  
}
// .cards-container{
//     display: flex;
//       flex-wrap: wrap;
//   .card{
//       margin-top: 16px;
//         margin-bottom: 8px;
//         margin-right: 30px;
//         // justify-content: space-between;
//         &:nth-child(5n) {
//           margin-right: 0;
//         }
//   }
 
// }

// .dot {
//   position: absolute;
//   top: -90px;
//   right: 0;
// }

.empty {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-tab {
  font-size: 18px;
  border-bottom: 1px solid #e3e3e3;
}

.user-card {
  margin-right: 23px;

  &:last-child {
    margin-right: 0;
  }
}

.collect {
  display: flex;
  flex-wrap: wrap;

  .card {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 30px;

    &:nth-child(5n) {
      margin-right: 0;
    }
     .createnft{
        display: none;
        position: absolute;
        top:90px;
        right: 30px;
      }
     .edit{
          cursor: pointer;
        display: none;
        position: absolute;
        top:8px;
        right: 8px;
  
        &:hover{
           .editExplain {
             display: unset !important;
        } 
        }
      }
      .editExplain {
      // display: none !important;
      width: 110px;
      height: 30px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 35px;
      right: 0px;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }
    }
       &:hover {
    .createnft {
      display: unset;
    }
    .edit{
       display: unset;
    }
   

   
  }
  }
}

.mobile {
  .switch {
    position: unset;
  }

  .card {
    width: 100%;
    margin-right: 0px;
    overflow: hidden;
  }

  .user-list {
    display: flex;
    justify-content: space-between;
  }
  .user-card {
    width: 47%;
    margin-right: 0px;

    // &:last-child {
    //   margin-right: 0;
    // }
  }
}
</style>

<template>
  <div :class="{ 'personal-page': true, mobile: isMobile }">
    <div class="top">
      <v-img class="img" v-if="ban" :src="ban" cover alt="" />
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <div class="d-flex mb-n10 flex-column align-center">
        <div class="avatar-box align-self-lg-start rounded-circle">
          <v-avatar :size="180">
            <v-img :src="src" alt="" />
          </v-avatar>
        </div>
      </div>
      <div
        class="ml-lg-8 mt-5 mt-lg-6 d-flex flex-column align-center align-lg-start"
      >
      <div class="showtip d-flex flex-lg-row justify-space-between" >
        <div style="font-size: 40px; font-weight: bold">{{ myInfo.name }}</div>
         
        </div>
        
        <div class="no_copy d-flex flex-lg-row flex-column">
          <div>
             <span v-if="!isMobile" class="No">{{ myInfo.address }}</span>
          <span v-if="isMobile" class="No">{{
            myInfo.address | didfilter
          }}</span>
          <img
            class="copy ml-2"
            :class="{ click: isCopy }"
            src="@/assets/icons/icon_copy.png"
            @click="onCopyClick"
          />
          </div>

          <div>
             <span
            style="
              margin-left: 47px;
              width: 18px;
              height: 19px;
              font-size: 13px;
            "
            >{{ $t("profile_link") }}</span
          >
          <img
            class="copyProfile ml-2"
            :class="{ copy_profile: isCopyProfile }"
            src="@/assets/icons/icon_copy.png"
            @click="onProfileClick"
          />
          </div>
         
          
        </div>

         <div class="linkurl" style="margin-top: -3px"  v-if="myInfo.twitter && myInfo.twitter != 'null' && myInfo.twitterVerify">
          <!-- <div
            class="mr-6 mt-1 media"
            v-if="myInfo.instagram && myInfo.instagram != 'null'"
          >
             <a :href="Instagram" target="_blank">
                 <img
              class="mr-2 mb-n2"
              style="margin-left: 0"
              src="@/assets/icons/icon_Instagram.png"
              alt=""
            />
            <span class="text-caption">{{ decodeURIComponent(myInfo.instagram)  }}</span>
            </a>
          </div> -->
              
           
          <div
            class="mr-6 mt-1 media"   
          >
            <a :href="Twitter" target="_blank">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_twitter1.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.twitterName) }}</span>
                <img
               v-if="myInfo.twitterVerify"
              class="mr-2 mb-n1"
              style="margin-left: 4px;width:16px;height:16px;"
              src="@/assets/icons/icon_verified.png"
              alt=""
            />
            </a>
          </div>
          <!-- <div
            class="mr-6 mt-1 media"
            v-if="myInfo.youtube && myInfo.youtube != 'null'"
          >
            <a :href="Youtube" target="_blank">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_youtube.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.youtube) }}</span>
            </a>
          </div> -->
          <!-- <div
            class="mr-6 mt-1 media"
            v-if="myInfo.homepage && myInfo.homepage != 'null'"
          >
            <a :href="Homepage" target="_blank">
              <img
                class="mr-2 mb-n2"
                src="@/assets/icons/icon_site.png"
                alt=""
              />
              <span class="text-caption">{{ decodeURIComponent(myInfo.homepage) }}</span>
            </a>
          </div> -->
        </div>
        <div class="cont d-flex flex-wrap" v-html="myInfo.description" v-if="myInfo.description"></div>
         <div class="d-flex flex-lg-row flex-column">
            <div class="d-flex flex-lg-row flex-column mt-2 follow">
          <div class="">
            <div class="num">{{ myInfo.fansNumber }}</div>
            <div class="sub">{{ $t("avatarAvatarInfoFans") }}</div>
          </div>
          <div class="ml-6">
            <div class="num">{{ myInfo.followCount }}</div>
            <div class="sub">{{ $t("fansSubscrible") }}</div>
          </div>
        </div>
            <div
                class="footer ml-lg-16  d-flex flex-row"
                v-if="myInfo.fansNumber > 0"
              >
                <div class="avatar-name mt-lg-5 mt-2">
                  {{ $t("avatarAvatarInfoFans") }}
                </div>
                <div class="mt-lg-4" style="position: relative; height: 30px">
                  <div
                    class="avatar"
                    :style="{ left: `${index * 18}px` }"
                    v-for="(info, index) of avatars"
                    :key="index"
                    @click="avatarClick"
                  >
                    <v-avatar size="28">
                      <v-img
                        v-if="info.src == null"
                        src="@/assets/default.png"
                        alt=""
                      />
                      <v-img v-else :src="info.src" alt="" />
                    </v-avatar>
                  </div>
                </div>
              </div>
         </div>
       
      </div>
  
    </div>
    
    <!-- <div clsss="pb-4" style="border-bottom:1px solid red;margin-left:20px; !important"> Collections</div> -->
    <!-- <BookmarksTabs class="mt-12" @onTabsClick="onTabsClick">
			<template v-slot="{ index }">
				<div class="pt-11" style="min-height:500px">
					<Collections v-if="index === 0" @list="getlist" />
					<MyCreations v-else />
				</div>
			</template>
		</BookmarksTabs> -->
<!-- 		
    <div class="switch d-flex justify-space-between">
      <div class="image">
        <img class="nft" :src="nftImage" @click="mode_click('nft')" />
        <img
          class="collection"
          :src="collectionImage"
          @click="mode_click('collection')"
        />
      </div>
      <div class="select d-flex flex-row">
        <div class="clearmode box d-flex flex-row">
          <div class="checkbox">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content">
            {{ $t("Clean mode") }}
          </div>
        </div>
        <SortSelect
          class="mr-1 dot mt-lg-1 ml-15"
          :list="selectList"
          v-model="activeSort"
          v-if="showModel == 'nft'"
        />
        <img
          :class="[rotate ? 'go' : '']"
          class="refresh"
          src="@/assets/icons/icon_refresh.png"
          @click="refresh"
        />
      </div>
    </div>  -->
     <div class="" style="min-height: 500px">
      <Collections @list="getlist" />
    </div>

    <div class="right_bottom" v-if="!isMobile && showRightBottom">
      <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
    </div>

    <v-dialog v-model="opencard">
      <carousel
        class="otherpage"
        :list="list"
        v-if="opencard"
        @getOpen="OpenCard"
      ></carousel>
    </v-dialog>
    <v-dialog fullscreen v-model="openLargemg">
      <large-img
        ref="largeDialog"
        v-if="openLargemg"
        :productPic="productPic"
        @getOpen="Open"
      >
      </large-img>
    </v-dialog>
    <v-dialog v-model="openAppeal">
         <accountAppeal @getOpen="OpenApp"></accountAppeal>
      </v-dialog>
  </div>
</template>

<script lang='js'>
import UserCount from "@/components/UserCount.vue";
import BookmarksTabs from "@/components/BookmarksTabs.vue";
import AccountAppeal from "@/components/popup/accountAppeal.vue";
import Collections from "./components/Collections.vue";
import MyCreations from "./components/MyCreations.vue";
import api from "@/api";
import {
	getFileSrc
} from "@/utils/file";
import defaultImg from "@/assets/default.png";
import Carousel from "@/components/carousel";
import LargeImg from "@/components/largeImg.vue";
import ScrollX from "@/components/scroll/ScrollX.vue";
import SortSelect from '@/components/SortSelect.vue';
import logoimg from "@/assets/default.png";
import { getSettingArr } from "@/utils/helper";

const BANNER_KEY = "BANNER";
const AVATAR_KEY = "AVATAR";
const SYNOPSIS_KEY = "SYNOPSIS";

export default {
	name: "PersonalPage",
	components: {
		UserCount,
		BookmarksTabs,
		Collections,
		MyCreations,
		Carousel,
		LargeImg,
		ScrollX,
    SortSelect,
    AccountAppeal,
   
	},
	props: {},
	data: function () {
		return {
      openAppeal: false,
      logoimg,
			myInfo: {},
      avatars: [],
      isCopyProfile:false,
			src: "",
			ban: "",
			defaultImg,
			opencard: false,
			list: [],

			isCopy: false,
			showRightBottom: false,

			loading: false,
			selection: 1,
			Twitter: '',
			Youtube: '',
			Homepage: '',

			//展示图片
			imgList: [
				//接口获取的三张图
			],
			openLargemg: false,
			productPic: '',
			joinTime: '',
			bscAddress: window.bscAddress,
		};
	},
	computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},
	filters: {
		didfilter: function (value) {
			if (value && value.length > 24) {
				return value.substr(0, 12) + "..." + value.substr(-12);
			} else {
				return "";
			}
		}
	},
	mounted() {
    this.showNoRead();
		this.getInfo();
    window.eventBus.$on('LangChange', this.onLangChange);
      // let json = window.localStorage.getItem("key_user");
      if(!this.$store.state.did){
          this.$router.push({ name: "Home" });
      }
	},
	methods: {
		onLangChange() {
			this.avatars = [];
			this.imgList = []

			this.getInfo();
		},

		reserve() {
			this.loading = true;
			setTimeout(() => (this.loading = false), 2000);
		},
		forceUpdate() {
			this.$forceUpdate();
		},
		async getInfo() {
			// let params = { did: this.$store.state.did };
			// let res = await api.home.getMyInfo(params);

			let params = {
				address: this.$store.state.did,
			};

			let res = await api.home.loopringUserInfo(params);
			this.myInfo = res.data;
   if (this.myInfo.profilePhoto != null && this.myInfo.profilePhoto != '') {
          this.src = await getFileSrc(AVATAR_KEY, this.myInfo.profilePhoto);
        } else {
          this.src = logoimg;
        }
        if(res.data.fansInfoDtoList){
           let fanSrcList = res.data.fansInfoDtoList;
           let fansrc;
        fanSrcList.forEach(async (v) => {
          if (v.fansPhoto != null && v.fansPhoto !="") {
            fansrc = await getFileSrc(AVATAR_KEY, v.fansPhoto);
            
          }else{
            fansrc = logoimg
          }
          this.avatars.push({ src: fansrc });
        });
        }

			if (this.myInfo.profilePhoto) {
				this.src = await getFileSrc(AVATAR_KEY, this.myInfo.profilePhoto);
			} else {
				this.src = defaultImg;
			}

			if (this.myInfo.background != null) {
				this.ban = await getFileSrc(BANNER_KEY, this.myInfo.background);
				if (this.ban == "null") this.ban = "";
			} else {
				this.ban = defaultImg
			}


			if (res.data.synopsisImage) {
				let imgLists = (res.data.synopsisImage).split(",");
				imgLists.forEach(async (v) => {
					if (v != '') {
						let imgsrc = await getFileSrc(SYNOPSIS_KEY, v);
						this.imgList.push({ src: imgsrc });
					}
				});
			}
			if (this.myInfo.joinTime != null) {
				let t = this.$t("otherpage_join")
				let reg = new RegExp('【N】', 'g')
				this.joinTime = t.replace(reg, this.myInfo.joinTime);

			}
			if (this.myInfo.twitter != null) {
				let newtwitter = this.myInfo.twitter.indexOf("http");
				if (newtwitter == 0) {
					this.Twitter = `${this.myInfo.twitter}`
				}
				if (newtwitter == -1) {
					this.Twitter = `https://${this.myInfo.twitter}`
				}
			}
			if (this.myInfo.youtube != null) {
				let newyoutube = this.myInfo.youtube.indexOf("http");
				if (newyoutube == 0) {
					this.Youtube = `${this.myInfo.youtube}`
				}
				if (newyoutube == -1) {
					this.Youtube = `https://${this.myInfo.youtube}`
				}
			}
			if (this.myInfo.homepage != null) {
				let newhomepage = this.myInfo.homepage.indexOf("http");
				if (newhomepage == 0) {
					this.Homepage = `${this.myInfo.homepage}`
				}
				if (newhomepage == -1) {
					this.Homepage = `https://${this.myInfo.homepage}`
				}
			}
			
			if (this.myInfo.description)
				this.myInfo.description = this.myInfo.description.replace(/\n/g, "<br/>")
			if (!this.myInfo) {
				this.myInfo = {
					did: this.$store.state.did,
					fansNumber: 0,
					followCount: 0,
					workCount: 0
				}
			}

			if (this.myInfo.profilePhoto) {
				this.src = await getFileSrc(AVATAR_KEY, this.myInfo.profilePhoto);
			} else {
				this.src = defaultImg;
			}

			if (this.myInfo.background != null) {
				this.ban = await getFileSrc(BANNER_KEY, this.myInfo.background);
				if (this.ban == "null") this.ban = defaultImg;
			} else {
				this.ban = defaultImg
			}
		
		},
		openCarousel() {
			this.opencard = true;
		},
		OpenCard(e) {
			this.opencard = e;
		},
		openLargeImg(item) {
			this.productPic = item.src
			this.openLargemg = true;
			// this.$refs.largeDialog.playVideo();
		},
		Open(e) {
			this.openLargemg = e;
    },
    accAppealBtn(){
      this.openAppeal  = true
    },
    OpenApp(e) {
         this.openAppeal = e;
      },
		getlist(dt) {
			this.list = dt;
			if (this.list.length > 0) {
				this.showRightBottom = true
			} else {
				this.showRightBottom = false
			}
		},
		onCopyClick() {
			var input = document.createElement('input')
			input.value = this.$chainName == "UPTICK EVM" ? this.bscAddress : this.myInfo.address;
			document.body.appendChild(input);
			input.select();
			document.execCommand('copy');
			document.body.removeChild(input);
			this.isCopy = true;
			setTimeout(() => this.isCopy = false, 100);
    },
    	onProfileClick(){
				console.log(" wxl -- myInfo ----")
				console.log( window.location.href)
				var input = document.createElement('input')
				input.value = "https://loopring.upticknft.com/otherpage?did="+this.myInfo.address;;
				document.body.appendChild(input);
				input.select();
				document.execCommand('copy');
				document.body.removeChild(input);
				this.isCopyProfile = true;
				setTimeout(() => this.isCopyProfile = false, 100);
			},
		onMediaClick(val) {
			window.open(val, '_blank');
		},
		onMediaClick1() {
			window.open('www.baidu.com', '_blank');
		},
		avatarClick() {
			this.$router.push({ name: "Fans" })
		},
		onTabsClick(v) {
			v == "Collections" ? this.showRightBottom = true : this.showRightBottom = false;
		},
		    mode_click(mode) {
      this.showModel = mode;
      mode == "nft" ? this.nftImage = require("@/assets/icons/icon_cj_nft.png") : this.nftImage = require("@/assets/icons/icon_cj_nft_g.png");
      mode == "collection" ? this.collectionImage = require("@/assets/icons/icon_cj_collection.png") : this.collectionImage = require("@/assets/icons/icon_cj_collection_g.png");
      this.pageNumber = 1;
      this.list = [];
    //   if (mode == "nft") {
    //     this.getColleectList();
    //   } else if (mode == "collection") {
    //     this.getCollectionList();
    //   }
    },
     async showNoRead(){
    const json = localStorage.getItem("key_user");
    if (json) {
      let res2 = await api.home.isNewMessage({userAddress:this.$store.state.did,pushTypes:getSettingArr()});
      let newMsgCount = res2.data;


     // 有新消息进行弹窗
     if(newMsgCount >0){
        let params={
        pageSize: 10,
        pageNumber: 1,
        owner:this.$store.state.did,
        isRead:false,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContent,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
	}
};
</script>

<style lang='scss' scoped>
a {
  text-decoration: none;
  vertical-align: middle;

  img,
  span {
    display: inline-block;
  }
}

img {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 7px 0 0;
}

.personal-page {
  .top {
    height: 301px;
    background-color: #f8f6fd;

    .img {
      width: 100%;
      height: 100%;
    }
  }
  .switch {
    margin-top: 10px;
    .image {
			display:flex;
				align-items: center;
      .nft {
        margin-left: 20px;
        width: 22px;
        height: 28px;
      }

      .collection {
        width: 25px;
        height: 25px;
        margin-left: 30px;
      }
    }
    .select {
		display:flex;
				align-items: center;
		.clearmode{
			.checkbox{

			}
			.content{
				display:flex;
				align-items: center;
			}

		}
      .refresh {
        height: 18px;
        width: 18px;
        margin-top: 5px;
        margin-left: 15px;
      }

      .go {
        transform: rotate(360deg);
        transition: all 2s;
        pointer-events: none;
        user-select: none;
      }
    }
  }
 .footer {
      .avatar-name {
        font-weight: bold;;
        font-size: 14px;
        color: #270645;
        font-weight: bold;
        padding-right: 14px;
      }

      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        background: white;
        padding: 2px;
        border-radius: 50%;
        // cursor: pointer;
      }
    }
  .no_copy {
    .No {
      padding-top: 4px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .copy {
      width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 23px;
      cursor: pointer;
    }
     .copyProfile {
      width: 13px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 23px;
      cursor: pointer;
    }

    .click {
      margin-top: 5px;
    }
     .copy_profile {
      margin-top: 5px;
    }
  }

  .cont {
    margin-top: 12px;
    max-width: 842px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #270645;
  }

  .linkurl {
    margin-bottom: 12px;

    .media {
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;

      a {
        img,
        span {
          display: inline;
        }
      }

      img,
      span {
        display: inline;
      }
    }
  }

  .avatar-box {
    position: relative;
    top: -40px;
    left: 0;
    border: 6px white solid;
  }

  .introduction {
    display: -webkit-box;
    overflow: hidden;
    font-size: 13px;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .imgbox {
    max-width: 996px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    .box {
      height: 300px;
      // width:auto;
      margin-right: 5px;

      img {
        width: auto;
        height: 300px;
        display: block; // object-fit: contain;
      }
    }
  }

  .info-box > div:first-of-type {
    width: 260px;
  }
    .num {
      font-weight: bold;;
      font-size: 15px;
      color: #270645;
      font-weight: bold;
    }
    .sub {
      font-family: Helvetica;
      font-size: 14px;
      color: #766983;
    }

  &.mobile {
    .follow {
      display: flex !important;
      flex-direction: row !important;
    }
    .showtip{
      width: 100px !important;
    }
    .pt-11 {
      padding-top: 20px !important;
    }

    .info-box {
      width: 100%;

      > div {
        width: 100%;
      }

      .ingbox {
        margin-top: 25px;
        margin-bottom: 30px;

        // width:100%;
        .box {
          height: 350px;

          // width:263px;
          img {
            width: auto;
            height: 100%;
            // object-fit: contain;
          }
        }
      }
    }

    .linkurl {
      margin-bottom: 12px;

      .media {
        display: inline-block;
        word-wrap: break-word;
        word-break: break-all;

        // width:100%;
        a {
          img,
          span {
            display: inline;
          }
        }

        img,
        span {
          display: inline;
        }
      }
    }
  }
}

.right_bottom {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 200;

  img {
    display: block;
    width: 59px;
    height: 59px;
    margin-top: 35px;
  }
}

.avatar {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}

</style>

<template>
  <div :class="{ mobile: isMobile }">
    <div class="d-flex flex-grow-1 mb-0 t">
      <img v-if="!isMobile" :class="[rotate ? 'go' : '']" class="refresh" src="@/assets/icons/icon_refresh.png" @click="refresh" />
    </div>
    <div class="cards-container">
      <div v-if="empty" class="empty">{{ $t('EmptyHere') }}</div>
      <CollectCard class="collect" v-for="(item, i) in list" :key="i" v-scroll="onScroll" :hash="item.imgUrl"
        :pageValue="item" type="createorPage">
        <!-- <span class="name">{{ item.name }}</span>
          <span class="num">{{ item.amount }}</span> -->
        <v-img class="imgdel" :class="{ 'showdel': item.amount == 0 }" src="@/assets/icons/icon_delete.png"
          @click="delclick(item)" />
        <v-btn v-if="item.contractStatus!=2" class="uc createnft" width="150px" height="41px" rounded @click="createNft(item)">+ Create NFT</v-btn>
      </CollectCard>
    </div>
    <loading :isShowLoading="isShowLoading"></loading>
    <uComponents ref="ucom"></uComponents>
    <PromptBox ref="promptBox" @commit="promptCommit"></PromptBox>
  </div>
</template>

<script lang='js'>
import CollectCard from "@/components/workCard/CollectCard.vue";
import api from "@/api"
import Loading from "@/components/loading.vue";
import PromptBox from "@/components/PromptBox.vue"

export default {
  name: 'MyCreations',
  components: { CollectCard, Loading, PromptBox },
  props: {
    id: {
      type: Number
    }
  },
  data: function () {
    return {
      pageNumber: 0,
      pageSize: 20,
      totalPage: false,
      list: [],
      activeTab: undefined,
      tabList: [

        { value: "undefined", label: this.$t('otherpageTabsPageAll') },
        { value: "3", label: this.$t('otherpageTabsPageArtwork') },
        { value: "4", label: this.$t('amCreateCollectibles') },
        { value: "5", label: this.$t('createCategoriesPhotography') },
        { value: "7", label: this.$t('amCreateVideo') },
      ],
      isShowLoading: false,
      empty: false,
      rotate: false
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    handler: function () {
      return {
        activeTab: this.activeTab,
      }
    }
  },
  watch: {
    handler: {
      handler: function () {
        this.pageNumber = 0;
        this.list = [];
        this.getMyList()
      },
      deep: true
    }
  },
  mounted() {
    window.eventBus.$on('LangChange', this.onLangChange);
    window.addEventListener("scroll", this.onScroll);
    this.getMyList()
  },
  methods: {
    refresh() {
      this.rotate = true;
      setTimeout(() => { this.rotate = false }, 2000);
      this.pageNumber = 0;
      this.list = [];
      this.getMyList();
    },
    async getMyList() {
      // this.list=[];
      let index = 0;
      if (this.activeTab == 0) {
        index = ''
      } else if (this.activeTab == 1) {
        index = 3
      } else if (this.activeTab == 2) {
        index = 4
      } else if (this.activeTab == 3) {
        index = 5
      } else if (this.activeTab == 4) {
        index = 7
      }
      this.pageNumber++;
      let params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner: this.$store.state.did,
      };
      this.isShowLoading = true;
      let res = await api.home.nftContractList(params);
      let { list, totalPage } = res.data;
      this.totalPage = totalPage;
      // list = list.filter(v => v);

      if (this.pageNumber == 1) {
        this.list = list
      } else {
        if (list != null) {
          this.list = this.list.concat(list);
        }

      }
      this.isShowLoading = false;
      if ((this.pageNumber == 1 || this.pageNumber == 0) && list.length == 0) {
        this.empty = true;
      } else {
        this.empty = false;
      }

      this.$emit("list", this.list);
    },
    onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target.scrollingElement;

      if (scrollTop + clientHeight >= scrollHeight && (this.totalPage > this.pageNumber)) {
        // this.pageNumber++;
        this.getMyList();
      }
    },
    async createNft(item) {
      let json = localStorage.getItem("key_user_grade");
      let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.$router.push({ name: "CreateNft", query: { tokenAddress: item.address } });
            }
      // this.$router.push({ name: "CreateNft", query: { tokenAddress: item.address } });
    },
    delclick(item) {
      this.$refs.promptBox.show("You are about to burn the collection 【N】. This is permanent and cannot be recovered, please operate with caution."
          .replace("【N】","【"+item.name+"】"), item);
    },
    async promptCommit(item) {
      let params = {
        tokenAddress: item.address,
      }
      let res = await api.home.nftBurn(params);
      if (res.success == true) {
        this.$toast("success", this.$t("success")).then(() => {
          this.refresh();
        })
      } else {
        this.$toast("error", this.$t(res.msg));

      }
    },
    onLangChange() {
      this.tabList = [
        { value: "undefined", label: this.$t('otherpageTabsPageAll') },
        { value: "3", label: this.$t('otherpageTabsPageArtwork') },
        { value: "4", label: this.$t('amCreateCollectibles') },
        { value: "5", label: this.$t('createCategoriesPhotography') },
        { value: "7", label: this.$t('amCreateVideo') },
      ]
    }
  },
};

</script>

<style lang='scss' scoped>
.cards-container {
  display: flex;
  flex-wrap: wrap;

  .v-card {
    margin-right: 29px;
    margin-bottom: 29px;

    &:nth-child(5n) {
      margin-right: 0;
    }
  }

  .v-card.mobile {
    width: 100%;
    margin-right: 0;
  }

  .empty {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.v-tab {
  font-size: 18px;
  border-bottom: 1px solid #e3e3e3;
}

.refresh {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 0px;
  top: -40px;
}

// .aa{
//       transition: all 2s;
//   }

.go {
  transform: rotate(360deg);
  transition: all 2s;
  pointer-events: none;
  user-select: none;
}



.collect {
  position: relative;
  display: flex;
  justify-content: center;

  // align-items: flex-end;
  // background-size: 90%;
  &::v-deep .v-image__image {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    .createnft {
      display: unset;
    }

    .showdel {
      display: unset;
    }
  }

  .name {
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 18px;
    left: 18px;
  }

  .num {
    position: absolute;
    position: absolute;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
    top: 42px;
    left: 18px;
  }

  .imgdel {
    position: absolute;
    right: 18px;
    top: 18px;
    width: 25px;
    height: 25px;
    z-index: 9;
    display: none;
    cursor: pointer;
  }

  .createnft {
    position: absolute;
    bottom: 26px;
    display: none;
  }
}

</style>
